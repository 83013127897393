import type { VulkConfig } from '/@src/types'

/**
 * Set your default Navbar by importing a vue component
 * Note: If you rename the import, make sure to update the type definition bellow
 *
 * @example import Navbar from '/@src/components/blocks/navbar-blocks/NavbarA.vue'
 */
import Navbar from '/@src/components/blocks/navbar-blocks/NavbarA.vue'

// Additonal megamenu components required by the Navbar
import CompanyMegamenu from '/@src/components/navigation/navbar/megamenu/CompanyMegamenu.vue'
import ProductMegamenu from '/@src/components/navigation/navbar/megamenu/ProductMegamenu.vue'
import ResourcesMegamenu from '/@src/components/navigation/navbar/megamenu/ResourcesMegamenu.vue'

/**
 * Set your default Footer by importing a vue component
 * Note: If you rename the import, make sure to update the type definition bellow
 *
 * @example import Footer from '/@src/components/blocks/footer-blocks/FooterA.vue'
 */
import Footer from '/@src/components/blocks/footer-blocks/FooterB.vue'

import Widget from '/@src/components/blocks/Widget.vue'

// Auto detect the type of the imported components
type NavbarType = typeof Navbar
type FooterType = typeof Footer
type WidgetType = typeof Widget
type AppConfig = VulkConfig<NavbarType, FooterType, WidgetType>

/**
 * This is the main configuration file for the app
 */
export default {
  name: '8D-1',
  title: '8D-1 AI Assistants',
  description:
    '8D-1 manages incoming leads, customer development, screening interviews, and other types of chats we dread having on weekends.',
  url: import.meta.env.VITE_APP_URL || 'https://8D-1.com/',
  image: 'https://8d-1.com/assets/illustrations/8dmain.png',
  theme: {
    // Auto detect system color scheme
    default: 'system',
    // Display the theme switcher
    toggle: true,
    // Disable transitions to avoid transition flickering when switching theme
    disableTransitions: true,
  },
  logo: {
    src: '/assets/logo/logo_horizontal.svg',
    // srcDark: '/assets/logo/logo-dark.svg',
    width: 45,
    height: 45,
  },
  layout: {
    transition: 'fade-fast',
    backToTop: {
      // Display the back to top button
      enabled: true,
    },
    navbar: {
      enabled: true,
      component: Navbar,
      props: {
        // define default props for Navbar here, you can override them using page meta
        cta: {
          to: '/signup',
          label: 'Sign Up',
        },
        items: [
          {
            label: 'Product',
            megamenu: ProductMegamenu,
          },
          {
            label: 'Pricing',
            to: '/pricing',
          },
          {
            label: 'Company',
            megamenu: CompanyMegamenu,
          },
          {
            label: 'Ressources',
            megamenu: ResourcesMegamenu,
          },
        ],
      },
    },
    footer: {
      enabled: true,
      component: Footer,
      props: {
        // define default props for Footer here, you can override them using page meta
        color: 'dark',
        content: {
          cta: {
            title: 'Get interview results directly to your database',
            subtitle: 'Request custom integration',
            benefits: [
              {
                label: 'AI-powered tech',
              },
              {
                label: 'Hours of time saved',
              },
            ],
            links: [
              {
                label: 'Learn More',
                to: '/',
              },
              {
                label: "Let's Talk",
                to: '/',
              },
            ],
          },
          newsletter: {
            action: '#',
            method: 'POST',
          },
          columns: [
            {
              title: 'Product',
              links: [
                {
                  label: 'Core features',
                  to: '/',
                },
                {
                  label: 'Extensions',
                  to: '/',
                },
                {
                  label: 'Marketing',
                  to: '/',
                },
                {
                  label: 'Sales Management',
                  to: '/',
                },
                {
                  label: 'Software',
                  to: '/',
                },
              ],
            },
            {
              title: 'Company',
              links: [
                {
                  label: 'Register',
                  to: '/',
                },
                {
                  label: '14-Day Trial',
                  to: '/',
                },
                {
                  label: 'About Us',
                  to: '/',
                },
                {
                  label: 'Careers',
                  to: '/',
                },
                {
                  label: 'Latest News',
                  to: '/',
                },
              ],
            },
            {
              title: 'API',
              links: [
                {
                  label: 'Getting Started',
                  to: '/',
                },
                {
                  label: 'API Reference',
                  to: '/',
                },
                {
                  label: 'Developer Guide',
                  to: '/',
                },
                {
                  label: 'GitHub',
                  to: '/',
                },
              ],
            },
            {
              title: 'Assistance',
              links: [
                {
                  label: 'Help Center',
                  to: '/',
                },
                {
                  label: 'Support Requests',
                  to: '/',
                },
                {
                  label: 'FAQs',
                  to: '/',
                },
                {
                  label: 'Licensing',
                  to: '/',
                },
              ],
            },
          ],
          socials: [
            {
              icon: 'fa-brands:facebook-f',
              link: '/',
              name: 'Facebook',
            },
            {
              icon: 'fa-brands:twitter',
              link: '/',
              name: 'Twitter',
            },
            {
              icon: 'fa-brands:linkedin-in',
              link: '/',
              name: 'Linkedin',
            },
            {
              icon: 'fa-brands:youtube',
              link: '/',
              name: 'Youtube',
            },
            {
              icon: 'fa-brands:github',
              link: '/',
              name: 'GitHub',
            },
          ],
          links: [
            {
              label: 'Contact Us',
              to: '/',
            },
            {
              label: 'Privacy',
              to: '/',
            },
            {
              label: 'Terms Of Service',
              to: 'terms',
            },
          ],
          copyright:
            '© 2024 Created and coded by Ivan Sokolov. All rights reserved.',
        },
      },
    },
    widget: {
      enabled: true,
      component: Widget,
      props: {
        assistantId: '2051',
        buttonId: '34',
        containerId: 'widget-preview-button',
        darkMode: 'dark',
        name: 'null',
        email: 'null',
        stickyPosition: 'bottom-right',
      },
    },
  },
} as AppConfig
