import {
  createRouter as createClientRouter,
  createWebHistory,
  createMemoryHistory,
  type RouteRecordRaw,
  type Router,
} from 'vue-router'
import routes from 'pages-generated'
import { authGuard } from '@auth0/auth0-vue'

type AnyRouteRecord = RouteRecordRaw

const additionalRoutes: AnyRouteRecord[] = []

export function createRouter(): Router {
  const isServer = typeof window === 'undefined'
  const allRoutes = markRoutesAsAuthRequired([...additionalRoutes, ...routes])

  const router = createClientRouter({
    history: isServer ? createMemoryHistory() : createWebHistory(),
    routes: allRoutes,
    scrollBehavior: !isServer
      ? (to, from, savedPosition) => {
          if (to.hash) {
            return {
              el: to.hash,
              behavior: 'smooth',
              top: to.hash ? 0 : undefined,
            }
          }
          if (savedPosition) return savedPosition
          return { top: 0, behavior: 'smooth' }
        }
      : undefined,
  })

  return router
}

function markRoutesAsAuthRequired(routes: AnyRouteRecord[]): AnyRouteRecord[] {
  const isServer = typeof window === 'undefined'

  return routes.map((route) => {
    if ('path' in route && route.path.startsWith('/app')) {
      return {
        ...route,
        meta: { ...route.meta, requiresAuth: true },
        // Only add authGuard in client environment
        beforeEnter: !isServer ? authGuard : undefined,
        children: route.children
          ? markRoutesAsAuthRequired(route.children)
          : undefined,
      } as AnyRouteRecord
    }
    return route
  })
}
