<script setup lang="ts">
export interface NumberBlock {
  prefix: string
  suffix: string
  value: number
  text: string
}

export interface NumberBlocksProps {
  numbers: NumberBlock[]
  inverted?: boolean
  animated?: boolean
}

const props = withDefaults(defineProps<NumberBlocksProps>(), {
  numbers: () => [],
  title: undefined,
  subtitle: undefined,
  content: undefined,
  animated: false,
})

</script>

<template>
  <div class="mx-auto columns">
    <div
      v-for="(number, index) in props.numbers"
      :key="index"
      class="column mx-auto has-text-centered"
    >
      <div class="py-4">
        <Title
          tag="h3"
          :size="1"
          weight="bold"
        >
          <span>{{ number.prefix }}</span>
          <Counter
            v-if="props.animated"
            :number="number.value"
          />
          <span
            v-else
            class="text-extra"
          >{{ number.value }}</span>
          <span>{{ number.suffix }}</span>
        </Title>
        <Subtitle
          tag="h3"
          :size="6"
          class="pb-4 text-light"
        >
          {{ number.text }}
        </Subtitle>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@media (width >= 768px) and (width <= 1024px) and (orientation: portrait) {
  .columns {
    display: flex;

    .column {
      &.is-12 {
        width: 100%;
        min-width: 100%;
      }

      &.is-3 {
        :deep(.title) {
          &.is-1 {
            font-size: 2.25rem;
          }
        }
      }
    }
  }
}

@media (width >= 768px) and (width <= 1024px) {
  .columns {
    display: block !important;
  }
}

.text-extra {
  font-family: var(--font-extra);
  font-size: 4rem;
}
</style>
