import { computed, ref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useUserStore } from '/@src/stores/userStore'
import { useToast } from 'vue-toastification'
import axios from 'axios'
import { useRoute } from 'vue-router'

interface AuthComposable {
  login: (returnTo?: string) => void
  signup: (returnTo?: string) => void
  logout: () => void
  syncUserWithServer: () => Promise<void>
  isAuthenticated: ComputedRef<boolean>
  user: ComputedRef<any>
  isLoading: ComputedRef<boolean>
  isSubscribed: ComputedRef<boolean>
  isSubscriptionChecked: Ref<boolean>
  checkSubscription: () => Promise<boolean>
  getAccessTokenSilently?: () => Promise<string>
  loginWithPopup?: () => Promise<void>
}

export default function useAuth(): AuthComposable {
  if (import.meta.env.SSR) {
    const isSubscriptionChecked = ref(false)
    return {
      login: () => {},
      signup: () => {},
      logout: () => {},
      syncUserWithServer: async () => {},
      isAuthenticated: computed(() => false),
      user: computed(() => null),
      isLoading: computed(() => false),
      isSubscribed: computed(() => false),
      isSubscriptionChecked,
      checkSubscription: async () => false,
    }
  }

  const auth0 = useAuth0()
  const userStore = useUserStore()
  const route = useRoute()
  const toast = useToast()
  const isSubscriptionChecked = ref(false)
  const isAuthenticatedLocally = ref(false)

  if (!auth0 || !auth0.getAccessTokenSilently) {
    throw new Error('Auth0 is not properly initialized')
  }

  const { getAccessTokenSilently } = auth0

  const syncUserWithServer = async () => {
    try {
      const accessToken = await getAccessTokenSilently()
      const idTokenClaims = auth0.idTokenClaims.value

      if (!accessToken || !idTokenClaims) return

      const idToken = idTokenClaims.__raw

      const response = await axios.post(
        '/auth/sync',
        { id_token: idToken },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      )

      userStore.setUser(response.data.user)
      if (response.data.subscription) {
        userStore.setSubscriptionStatus(response.data.subscription)
      }
      isAuthenticatedLocally.value = true
    } catch (error) {
      console.error('Error syncing user:', error)
      // Show error toast
      toast.error(
        'Error synchronizingwith server. Please try logging in again.',
        {
          timeout: 3000, // Show for 3 seconds
          onClose: () => {
            // Reset local auth state
            isAuthenticatedLocally.value = false
            // Clear user store data
            userStore.clearUser()
            // Logout user after toast is closed
            auth0.logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            })
          },
        },
      )
    }
  }

  const checkSubscription = async () => {
    try {
      const token = await getAccessTokenSilently()
      if (!token) return false

      const result = await userStore.checkSubscription(token)
      isSubscriptionChecked.value = true
      return result
    } catch (error) {
      console.error('Error checking subscription:', error)
      return false
    }
  }

  return {
    login: (returnTo = route.fullPath) => {
      auth0.loginWithRedirect({
        appState: { target: returnTo },
      })
    },
    signup: (returnTo = route.fullPath) => {
      auth0.loginWithRedirect({
        authorizationParams: {
          screen_hint: 'signup',
        },
        appState: { target: returnTo },
      })
    },
    logout: () =>
      auth0.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      }),
    syncUserWithServer,
    isAuthenticated: computed(() => auth0.isAuthenticated.value),
    user: computed(() => auth0.user.value),
    isLoading: computed(() => auth0.isLoading.value),
    isSubscribed: computed(() => userStore.isSubscribed),
    isSubscriptionChecked,
    checkSubscription,
    getAccessTokenSilently: auth0.getAccessTokenSilently,
    loginWithPopup: auth0.loginWithPopup,
  }
}
