<script setup lang="ts">
import config from '/@src/app.config'

const props = withDefaults(
  defineProps<{
    navbar?: boolean
    footer?: boolean
    widget?: boolean
    backToTop?: boolean
  }>(),
  {
    navbar: undefined,
    footer: undefined,
    widget: undefined,
    backToTop: undefined,
  },
)

const route = useRoute()
</script>

<template>
  <slot>
    <component
      :is="config.layout.navbar.component"
      v-if="
        config.layout?.navbar?.component &&
        (props.navbar ??
          route.meta?.navbar?.enabled ??
          config.layout?.navbar?.enabled)
      "
      v-bind="{
        ...((config.layout?.navbar?.props as any) ?? {}),
        ...(route.meta?.navbar?.props ?? {}),
      }"
    />

    <RouterView v-slot="{ Component }">
      <Transition
        :name="
          (route?.meta?.transition as string) ??
          config.layout?.transition ??
          'no-transition'
        "
        mode="out-in"
      >
        <component :is="Component" />
      </Transition>
    </RouterView>

    <component
      :is="config.layout.footer.component"
      v-if="
        config.layout.footer?.component &&
        (props.footer ??
          route.meta?.footer?.enabled ??
          config?.layout?.footer?.enabled)
      "
      v-bind="{
        ...((config?.layout?.footer?.props as any) ?? {}),
        ...(route.meta?.footer?.props ?? {}),
      }"
    />

    <component
      :is="config.layout.widget.component"
      v-if="
        config.layout.widget?.component &&
        (props.widget ??
          route.meta?.widget?.enabled ??
          config?.layout?.widget?.enabled)
      "
      v-bind="{
        ...((config?.layout?.widget?.props as any) ?? {}),
        ...(route.meta?.widget?.props ?? {}),
      }"
    />

    <BackToTop v-if="props.backToTop ?? route.meta?.backToTop" />
  </slot>
  
</template>
