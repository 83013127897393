import { ref } from 'vue'
import axios from 'axios'

export interface PublicStats {
  total_chats: number
  total_messages: number
  total_hours_saved: number
}

export interface FormattedStat {
  value: string
  text: string
}

export function usePublicStats() {
  const statistics = ref<FormattedStat[]>([])
  const error = ref<string | null>(null)
  const loading = ref(false)

  const fetchPublicStats = async () => {
    loading.value = true
    error.value = null

    try {
      const response = await axios.get<PublicStats>('/api/v1/public-stats')
      const data = response.data

      statistics.value = [
        { value: formatNumber(data.total_messages), text: "messages handled" },
        { value: formatNumber(Math.round(data.total_hours_saved)), text: "hours saved" },
        { value: formatNumber(data.total_chats), text: "chats initiated" }
      ]
    } catch (err) {
      error.value = 'Failed to fetch public stats'
      console.error('Error fetching public stats:', err)
    } finally {
      loading.value = false
    }
  }

  const formatNumber = (num: number): string => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  return {
    statistics,
    error,
    loading,
    fetchPublicStats
  }
}