<script setup>
const props = defineProps({
  columns: {
    type: Number,
    default: 4
  },
  limit: {
    type: Number,
    default: 3
  },
  links: {
    type: Boolean,
    default: false
  },
  rounded: {
    type: Boolean,
    default: false
  },
  inverted: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: 'Simple as a toaster'
  },
  subtitle: {
    type: String,
    default: 'Instantly create and share personalized AI agents for any task.'
  },

  features: {
    type: Array,
    default: () => [
      {
        "title": "Create an assistant easily and share a link to your bot with your target audience.",
        "image": "/assets/illustrations/task.svg",
        "darkImage": "/assets/illustrations/task_dark.svg"
      },
      {
        "title": "Your assistant chats with your audience, gathering the information you need.",
        "image": "/assets/illustrations/theychat.svg",
        "darkImage": "/assets/illustrations/theychat_dark.svg"
      },
      {
        "title": "Check back to review chat transcripts and get insights into your audience.",
        "image": "/assets/illustrations/result.svg",
        "darkImage": "/assets/illustrations/result_dark.svg"
      }
    ]
  }
});

const imageClasses = computed(() => [props.rounded && `image-rounded`]);

const columnClasses = computed(() => [props.columns && `is-${props.columns}`]);

const subtitleClasses = computed(() => [props.inverted && 'text-light']);
</script>
<template>
  <div class="py-6">
    <div class="is-flex is-justify-content-center mt-20 mb-20">
      <Title
        tag="h2"
        :size="3"
        weight="bold"
        leading
        :inverted="inverted"
      >
        {{ title }}
      </Title>
    </div>
    <div class="columns is-multiline b-columns-half-tablet-p">
      <div
        v-for="(feature, index) in features"
        :key="index"
        class="column mobile:mb-6 is-flex is-flex-direction-column is-align-items-center"
        :class="columnClasses"
      >
        <DarkImage
          :class="[...imageClasses]"
          :src="feature.image"
          :src-dark="feature.darkImage"
          alt=""
        />
        <div>
          <p class="paragraph hows max-w-3 pt-2 rem-5 has-text-centered">
            <span>{{ feature.title }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.image-rounded {
  border-radius: 1.5rem;
}

:deep(.action-link) {
  margin-top: 0.25rem;
}

.hows {
  color: var(--title-color)
}
</style>
