<template>
  <div id="widget-preview-button"></div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount } from 'vue'

// Определение пропсов для гибкости настройки виджета
const props = defineProps({
  assistantId: {
    type: String,
    default: '2051',
  },
  buttonId: {
    type: String,
    default: '34',
  },
  containerId: {
    type: String,
    default: 'widget-preview-button',
  },
  darkMode: {
    type: String,
    default: 'dark',
  },
  name: {
    type: String,
    default: 'null',
  },
  email: {
    type: String,
    default: 'null',
  },
  stickyPosition: {
    type: String,
    default: 'bottom-right',
  },
})

let scriptElement: HTMLScriptElement | null = null

onMounted(() => {
  // Создание скрипта виджета
  scriptElement = document.createElement('script')
  scriptElement.id = 'widget8D-script'
  //scriptElement.src = 'https://8d-1.com/widget/button.js'
  const origin = window.location.origin === 'http://localhost:3000' ? 'https://8d-1.com' : window.location.origin
  scriptElement.src = origin + '/widget/button.js'
  scriptElement.dataset.chatUrl = origin + '/chat'
  scriptElement.dataset.assistantId = props.assistantId
  scriptElement.dataset.buttonId = props.buttonId
  scriptElement.dataset.containerId = props.containerId
  scriptElement.dataset.darkMode = props.darkMode
  scriptElement.dataset.name = props.name
  scriptElement.dataset.email = props.email
  scriptElement.dataset.stickyPosition = props.stickyPosition
  scriptElement.async = true

  document.body.appendChild(scriptElement)
})

onBeforeUnmount(() => {
  // Удаление скрипта при размонтировании компонента
  if (scriptElement) {
    document.body.removeChild(scriptElement)
  }
})
</script>
